/* tslint:disable */
import {
  Warranty,
  UserModel,
  Payment,
  Flag,
  Product,
  ProductieStaat,
  Service,
  Log,
  ShoppingCart,
  InternalNote,
  SupplyOrder,
  Correspondence,
  ExactLog,
  DeliveryForm,
  Division,
  CancelQuestion,
  StandardOrder,
} from '../index';

declare var Object: any;
export interface OrderInterface {
  paymentStatus: string;
  payDirect: boolean;
  deferredPayment: boolean;
  storage?: any;
  trustpilotMailSend?: boolean;
  offerId?: string;
  shoppingCartId?: any;
  saleType?: string;
  saleLocation?: string;
  creditRating?: any;
  creditRatingPending?: boolean;
  creditsafeId?: string;
  selectedReasonDeleted?: string;
  exactConstructionDepot?: boolean;
  warrantyDecideLater?: boolean;
  warrantyOwnRiskExactSalesInvoiceId?: string;
  warrantyPrice?: number;
  mailSent?: any;
  standardOrderId?: any;
  deliveryCosts?: number;
  isSelfAssembly?: boolean;
  status: string;
  discountRecords?: Array<any>;
  extraProductDiscount?: number;
  notes?: string;
  disableCommunication: boolean;
  id?: any;
  userIdDeleted?: any;
  dateCreated?: Date;
  dateModified?: Date;
  userIdCreated?: string;
  userIdModified?: string;
  firstName: string;
  insertion?: string;
  lastName: string;
  emailAddress: string;
  shippingStreet: string;
  shippingHouseNumber: string;
  shippingZip: string;
  shippingCity: string;
  shippingPhone: string;
  shippingProvince?: string;
  shippingMunicipality?: string;
  shippingLocation?: any;
  invoiceStreet: string;
  invoiceHouseNumber: string;
  invoiceZip: string;
  invoiceCity: string;
  invoiceEmail: string;
  invoicePhone: string;
  invoiceCompany?: string;
  invoiceReference?: string;
  invoiceVAT?: string;
  invoiceProvince?: string;
  invoiceMunicipality?: string;
  invoiceLocation?: any;
  exactAccountId?: string;
  callAppointment?: Date;
  orderNumber: string;
  bumbalMeasurementId?: string;
  bumbalMeasurementExtraTime?: number;
  bumbalMeasurementTotalTime?: number;
  measurementDate?: Date;
  bumbalMeasurementEmployee?: string;
  bumbalMeasurementStatus?: string;
  appointmentCosts?: Array<any>;
  bumbalFlow?: string;
  bumbalDeliveryId?: string;
  bumbalDeliveryExtraTime?: number;
  bumbalDeliveryTotalTime?: number;
  deliveryDate?: Date;
  bumbalDeliveryEmployee?: string;
  bumbalDeliveryStatus?: string;
  bumbalShipmentId?: string;
  bumbalShipmentExtraTime?: number;
  bumbalShipmentTotalTime?: number;
  shipmentDate?: Date;
  bumbalShipmentEmployee?: string;
  bumbalShipmentStatus?: string;
  deleted?: boolean;
  dateDeleted?: Date;
  exactInvoicedAmount?: number;
  exactSalesOrderId?: string;
  exactSalesInvoiceIds?: Array<any>;
  exactSalesInvoiceAttachmentIds?: Array<any>;
  exactInvoiceRemark?: string;
  flagId?: any;
  invoiceDivisionId?: any;
  shippingDivisionId?: any;
  warranty?: Warranty;
  userDeleted?: UserModel;
  price?: any;
  payments?: Payment[];
  flag?: Flag;
  products?: Product[];
  productieStaat?: ProductieStaat[];
  services?: Service[];
  logs?: Log[];
  shoppingCart?: ShoppingCart;
  internalNote?: InternalNote[];
  supplyOrders?: SupplyOrder[];
  correspondences?: Correspondence[];
  exactLogs?: ExactLog[];
  deliveryForm?: DeliveryForm;
  invoiceDivision?: Division;
  shippingDivision?: Division;
  cancelQuestion?: CancelQuestion;
  standardOrder?: StandardOrder;
}

export class Order implements OrderInterface {
  'paymentStatus': string;
  'payDirect': boolean;
  'deferredPayment': boolean;
  'storage': any;
  'trustpilotMailSend': boolean;
  'offerId': string;
  'shoppingCartId': any;
  'saleType': string;
  'saleLocation': string;
  'creditRating': any;
  'creditRatingPending': boolean;
  'creditsafeId': string;
  'selectedReasonDeleted': string;
  'exactConstructionDepot': boolean;
  'warrantyDecideLater': boolean;
  'warrantyOwnRiskExactSalesInvoiceId': string;
  'warrantyPrice': number;
  'mailSent': any;
  'standardOrderId': any;
  'deliveryCosts': number;
  'isSelfAssembly': boolean;
  'status': string;
  'discountRecords': Array<any>;
  'extraProductDiscount': number;
  'notes': string;
  'disableCommunication': boolean;
  'id': any;
  'userIdDeleted': any;
  'dateCreated': Date;
  'dateModified': Date;
  'userIdCreated': string;
  'userIdModified': string;
  'firstName': string;
  'insertion': string;
  'lastName': string;
  'emailAddress': string;
  'shippingStreet': string;
  'shippingHouseNumber': string;
  'shippingZip': string;
  'shippingCity': string;
  'shippingPhone': string;
  'shippingProvince': string;
  'shippingMunicipality': string;
  'shippingLocation': any;
  'invoiceStreet': string;
  'invoiceHouseNumber': string;
  'invoiceZip': string;
  'invoiceCity': string;
  'invoiceEmail': string;
  'invoicePhone': string;
  'invoiceCompany': string;
  'invoiceReference': string;
  'invoiceVAT': string;
  'invoiceProvince': string;
  'invoiceMunicipality': string;
  'invoiceLocation': any;
  'exactAccountId': string;
  'callAppointment': Date;
  'orderNumber': string;
  'bumbalMeasurementId': string;
  'bumbalMeasurementExtraTime': number;
  'bumbalMeasurementTotalTime': number;
  'measurementDate': Date;
  'bumbalMeasurementEmployee': string;
  'bumbalMeasurementStatus': string;
  'appointmentCosts': Array<any>;
  'bumbalFlow': string;
  'bumbalDeliveryId': string;
  'bumbalDeliveryExtraTime': number;
  'bumbalDeliveryTotalTime': number;
  'deliveryDate': Date;
  'bumbalDeliveryEmployee': string;
  'bumbalDeliveryStatus': string;
  'bumbalShipmentId': string;
  'bumbalShipmentExtraTime': number;
  'bumbalShipmentTotalTime': number;
  'shipmentDate': Date;
  'bumbalShipmentEmployee': string;
  'bumbalShipmentStatus': string;
  'deleted': boolean;
  'dateDeleted': Date;
  'exactInvoicedAmount': number;
  'exactSalesOrderId': string;
  'exactSalesInvoiceIds': Array<any>;
  'exactSalesInvoiceAttachmentIds': Array<any>;
  'exactInvoiceRemark': string;
  'flagId': any;
  'invoiceDivisionId': any;
  'shippingDivisionId': any;
  warranty: Warranty;
  userDeleted: UserModel;
  price: any;
  payments: Payment[];
  flag: Flag;
  products: Product[];
  productieStaat: ProductieStaat[];
  services: Service[];
  logs: Log[];
  shoppingCart: ShoppingCart;
  internalNote: InternalNote[];
  supplyOrders: SupplyOrder[];
  correspondences: Correspondence[];
  exactLogs: ExactLog[];
  deliveryForm: DeliveryForm;
  invoiceDivision: Division;
  shippingDivision: Division;
  cancelQuestion: CancelQuestion;
  standardOrder: StandardOrder;
  constructor(data?: OrderInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Order`.
   */
  public static getModelName() {
    return 'Order';
  }
  /**
   * @method factory
   * @author Jonathan Casarrubias
   * @license MIT
   * This method creates an instance of Order for dynamic purposes.
   **/
  public static factory(data: OrderInterface): Order {
    return new Order(data);
  }
  /**
   * @method getModelDefinition
   * @author Julien Ledun
   * @license MIT
   * This method returns an object that represents some of the model
   * definitions.
   **/
  public static getModelDefinition() {
    return {
      name: 'Order',
      plural: 'Orders',
      path: 'Orders',
      idName: 'id',
      properties: {
        paymentStatus: {
          name: 'paymentStatus',
          type: 'string',
          default: 'notPaid',
        },
        payDirect: {
          name: 'payDirect',
          type: 'boolean',
          default: false,
        },
        deferredPayment: {
          name: 'deferredPayment',
          type: 'boolean',
          default: false,
        },
        storage: {
          name: 'storage',
          type: 'any',
        },
        trustpilotMailSend: {
          name: 'trustpilotMailSend',
          type: 'boolean',
          default: false,
        },
        offerId: {
          name: 'offerId',
          type: 'string',
        },
        shoppingCartId: {
          name: 'shoppingCartId',
          type: 'any',
        },
        saleType: {
          name: 'saleType',
          type: 'string',
        },
        saleLocation: {
          name: 'saleLocation',
          type: 'string',
        },
        creditRating: {
          name: 'creditRating',
          type: 'any',
        },
        creditRatingPending: {
          name: 'creditRatingPending',
          type: 'boolean',
        },
        creditsafeId: {
          name: 'creditsafeId',
          type: 'string',
        },
        selectedReasonDeleted: {
          name: 'selectedReasonDeleted',
          type: 'string',
        },
        exactConstructionDepot: {
          name: 'exactConstructionDepot',
          type: 'boolean',
        },
        warrantyDecideLater: {
          name: 'warrantyDecideLater',
          type: 'boolean',
        },
        warrantyOwnRiskExactSalesInvoiceId: {
          name: 'warrantyOwnRiskExactSalesInvoiceId',
          type: 'string',
        },
        warrantyPrice: {
          name: 'warrantyPrice',
          type: 'number',
        },
        mailSent: {
          name: 'mailSent',
          type: 'any',
        },
        standardOrderId: {
          name: 'standardOrderId',
          type: 'any',
        },
        deliveryCosts: {
          name: 'deliveryCosts',
          type: 'number',
          default: 0,
        },
        isSelfAssembly: {
          name: 'isSelfAssembly',
          type: 'boolean',
        },
        status: {
          name: 'status',
          type: 'string',
          default: 'created',
        },
        discountRecords: {
          name: 'discountRecords',
          type: 'Array&lt;any&gt;',
        },
        extraProductDiscount: {
          name: 'extraProductDiscount',
          type: 'number',
          default: 0,
        },
        notes: {
          name: 'notes',
          type: 'string',
        },
        disableCommunication: {
          name: 'disableCommunication',
          type: 'boolean',
          default: false,
        },
        id: {
          name: 'id',
          type: 'any',
        },
        userIdDeleted: {
          name: 'userIdDeleted',
          type: 'any',
        },
        dateCreated: {
          name: 'dateCreated',
          type: 'Date',
        },
        dateModified: {
          name: 'dateModified',
          type: 'Date',
        },
        userIdCreated: {
          name: 'userIdCreated',
          type: 'string',
        },
        userIdModified: {
          name: 'userIdModified',
          type: 'string',
        },
        firstName: {
          name: 'firstName',
          type: 'string',
        },
        insertion: {
          name: 'insertion',
          type: 'string',
        },
        lastName: {
          name: 'lastName',
          type: 'string',
        },
        emailAddress: {
          name: 'emailAddress',
          type: 'string',
        },
        shippingStreet: {
          name: 'shippingStreet',
          type: 'string',
        },
        shippingHouseNumber: {
          name: 'shippingHouseNumber',
          type: 'string',
        },
        shippingZip: {
          name: 'shippingZip',
          type: 'string',
        },
        shippingCity: {
          name: 'shippingCity',
          type: 'string',
        },
        shippingPhone: {
          name: 'shippingPhone',
          type: 'string',
        },
        shippingProvince: {
          name: 'shippingProvince',
          type: 'string',
        },
        shippingMunicipality: {
          name: 'shippingMunicipality',
          type: 'string',
        },
        shippingLocation: {
          name: 'shippingLocation',
          type: 'any',
        },
        invoiceStreet: {
          name: 'invoiceStreet',
          type: 'string',
        },
        invoiceHouseNumber: {
          name: 'invoiceHouseNumber',
          type: 'string',
        },
        invoiceZip: {
          name: 'invoiceZip',
          type: 'string',
        },
        invoiceCity: {
          name: 'invoiceCity',
          type: 'string',
        },
        invoiceEmail: {
          name: 'invoiceEmail',
          type: 'string',
        },
        invoicePhone: {
          name: 'invoicePhone',
          type: 'string',
        },
        invoiceCompany: {
          name: 'invoiceCompany',
          type: 'string',
        },
        invoiceReference: {
          name: 'invoiceReference',
          type: 'string',
        },
        invoiceVAT: {
          name: 'invoiceVAT',
          type: 'string',
        },
        invoiceProvince: {
          name: 'invoiceProvince',
          type: 'string',
        },
        invoiceMunicipality: {
          name: 'invoiceMunicipality',
          type: 'string',
        },
        invoiceLocation: {
          name: 'invoiceLocation',
          type: 'any',
        },
        exactAccountId: {
          name: 'exactAccountId',
          type: 'string',
        },
        callAppointment: {
          name: 'callAppointment',
          type: 'Date',
        },
        orderNumber: {
          name: 'orderNumber',
          type: 'string',
        },
        bumbalMeasurementId: {
          name: 'bumbalMeasurementId',
          type: 'string',
        },
        bumbalMeasurementExtraTime: {
          name: 'bumbalMeasurementExtraTime',
          type: 'number',
          default: 0,
        },
        bumbalMeasurementTotalTime: {
          name: 'bumbalMeasurementTotalTime',
          type: 'number',
        },
        measurementDate: {
          name: 'measurementDate',
          type: 'Date',
        },
        bumbalMeasurementEmployee: {
          name: 'bumbalMeasurementEmployee',
          type: 'string',
        },
        bumbalMeasurementStatus: {
          name: 'bumbalMeasurementStatus',
          type: 'string',
        },
        appointmentCosts: {
          name: 'appointmentCosts',
          type: 'Array&lt;any&gt;',
        },
        bumbalFlow: {
          name: 'bumbalFlow',
          type: 'string',
          default: 'default',
        },
        bumbalDeliveryId: {
          name: 'bumbalDeliveryId',
          type: 'string',
        },
        bumbalDeliveryExtraTime: {
          name: 'bumbalDeliveryExtraTime',
          type: 'number',
          default: 0,
        },
        bumbalDeliveryTotalTime: {
          name: 'bumbalDeliveryTotalTime',
          type: 'number',
        },
        deliveryDate: {
          name: 'deliveryDate',
          type: 'Date',
        },
        bumbalDeliveryEmployee: {
          name: 'bumbalDeliveryEmployee',
          type: 'string',
        },
        bumbalDeliveryStatus: {
          name: 'bumbalDeliveryStatus',
          type: 'string',
        },
        bumbalShipmentId: {
          name: 'bumbalShipmentId',
          type: 'string',
        },
        bumbalShipmentExtraTime: {
          name: 'bumbalShipmentExtraTime',
          type: 'number',
          default: 0,
        },
        bumbalShipmentTotalTime: {
          name: 'bumbalShipmentTotalTime',
          type: 'number',
        },
        shipmentDate: {
          name: 'shipmentDate',
          type: 'Date',
        },
        bumbalShipmentEmployee: {
          name: 'bumbalShipmentEmployee',
          type: 'string',
        },
        bumbalShipmentStatus: {
          name: 'bumbalShipmentStatus',
          type: 'string',
        },
        deleted: {
          name: 'deleted',
          type: 'boolean',
        },
        dateDeleted: {
          name: 'dateDeleted',
          type: 'Date',
        },
        exactInvoicedAmount: {
          name: 'exactInvoicedAmount',
          type: 'number',
        },
        exactSalesOrderId: {
          name: 'exactSalesOrderId',
          type: 'string',
        },
        exactSalesInvoiceIds: {
          name: 'exactSalesInvoiceIds',
          type: 'Array&lt;any&gt;',
        },
        exactSalesInvoiceAttachmentIds: {
          name: 'exactSalesInvoiceAttachmentIds',
          type: 'Array&lt;any&gt;',
        },
        exactInvoiceRemark: {
          name: 'exactInvoiceRemark',
          type: 'string',
        },
        flagId: {
          name: 'flagId',
          type: 'any',
        },
        invoiceDivisionId: {
          name: 'invoiceDivisionId',
          type: 'any',
        },
        shippingDivisionId: {
          name: 'shippingDivisionId',
          type: 'any',
        },
      },
      relations: {
        warranty: {
          name: 'warranty',
          type: 'Warranty',
          model: 'Warranty',
          relationType: 'hasOne',
          keyFrom: 'id',
          keyTo: 'orderId',
        },
        userDeleted: {
          name: 'userDeleted',
          type: 'UserModel',
          model: 'UserModel',
          relationType: 'belongsTo',
          keyFrom: 'userIdDeleted',
          keyTo: 'id',
        },
        price: {
          name: 'price',
          type: 'any',
          model: '',
          relationType: 'hasOne',
          keyFrom: 'id',
          keyTo: 'orderId',
        },
        payments: {
          name: 'payments',
          type: 'Payment[]',
          model: 'Payment',
          relationType: 'hasMany',
          keyFrom: 'id',
          keyTo: 'orderId',
        },
        flag: {
          name: 'flag',
          type: 'Flag',
          model: 'Flag',
          relationType: 'belongsTo',
          keyFrom: 'flagId',
          keyTo: 'id',
        },
        products: {
          name: 'products',
          type: 'Product[]',
          model: 'Product',
          relationType: 'hasMany',
          keyFrom: 'id',
          keyTo: 'orderId',
        },
        productieStaat: {
          name: 'productieStaat',
          type: 'ProductieStaat[]',
          model: 'ProductieStaat',
          relationType: 'hasMany',
          keyFrom: 'id',
          keyTo: 'orderId',
        },
        services: {
          name: 'services',
          type: 'Service[]',
          model: 'Service',
          relationType: 'hasMany',
          keyFrom: 'id',
          keyTo: 'orderId',
        },
        logs: {
          name: 'logs',
          type: 'Log[]',
          model: 'Log',
          relationType: 'hasMany',
          keyFrom: 'id',
          keyTo: 'instanceId',
        },
        shoppingCart: {
          name: 'shoppingCart',
          type: 'ShoppingCart',
          model: 'ShoppingCart',
          relationType: 'belongsTo',
          keyFrom: 'shoppingCartId',
          keyTo: 'id',
        },
        internalNote: {
          name: 'internalNote',
          type: 'InternalNote[]',
          model: 'InternalNote',
          relationType: 'hasMany',
          keyFrom: 'id',
          keyTo: 'linkId',
        },
        supplyOrders: {
          name: 'supplyOrders',
          type: 'SupplyOrder[]',
          model: 'SupplyOrder',
          relationType: 'hasMany',
          keyFrom: 'id',
          keyTo: 'orderId',
        },
        correspondences: {
          name: 'correspondences',
          type: 'Correspondence[]',
          model: 'Correspondence',
          relationType: 'hasMany',
          keyFrom: 'id',
          keyTo: 'fkId',
        },
        exactLogs: {
          name: 'exactLogs',
          type: 'ExactLog[]',
          model: 'ExactLog',
          relationType: 'hasMany',
          keyFrom: 'id',
          keyTo: 'instanceId',
        },
        deliveryForm: {
          name: 'deliveryForm',
          type: 'DeliveryForm',
          model: 'DeliveryForm',
          relationType: 'hasOne',
          keyFrom: 'id',
          keyTo: 'modelId',
        },
        invoiceDivision: {
          name: 'invoiceDivision',
          type: 'Division',
          model: 'Division',
          relationType: 'belongsTo',
          keyFrom: 'invoiceDivisionId',
          keyTo: 'id',
        },
        shippingDivision: {
          name: 'shippingDivision',
          type: 'Division',
          model: 'Division',
          relationType: 'belongsTo',
          keyFrom: 'shippingDivisionId',
          keyTo: 'id',
        },
        cancelQuestion: {
          name: 'cancelQuestion',
          type: 'CancelQuestion',
          model: 'CancelQuestion',
          relationType: 'belongsTo',
          keyFrom: 'selectedReasonDeleted',
          keyTo: 'code',
        },
        standardOrder: {
          name: 'standardOrder',
          type: 'StandardOrder',
          model: 'StandardOrder',
          relationType: 'belongsTo',
          keyFrom: 'standardOrderId',
          keyTo: 'id',
        },
      },
    };
  }
}
