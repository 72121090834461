import * as tslib_1 from "tslib";
//////////////////////////////
//                          //
//         Angular          //
//                          //
//////////////////////////////
import { OnInit } from '@angular/core';
//////////////////////////////
//                          //
//       Dependencies       //
//                          //
//////////////////////////////
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
//////////////////////////////
//                          //
//        App Imports       //
//                          //
//////////////////////////////
import { StandardProductApi } from '@app/_sdk';
export class StandardProductListModalComponent {
    /**
     * Constructor
     */
    constructor(activeModal, standardProductApi) {
        this.activeModal = activeModal;
        this.standardProductApi = standardProductApi;
    }
    /**
     * @description On init
     */
    ngOnInit() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            // Get standard products if no list is given
            if (!this.standardProducts) {
                this.standardProducts = yield this.standardProductApi
                    .find({
                    order: 'name',
                    include: [
                        {
                            relation: 'exactArticleWarehouseLocation',
                            scope: {
                                fields: ['itemStock'],
                            },
                        },
                        {
                            relation: 'deliveryCondition',
                            scope: {
                                include: {
                                    relation: 'deliveryCondition',
                                },
                            },
                        },
                        {
                            relation: 'deliveryMethod',
                        },
                        {
                            relation: 'productType',
                        },
                        {
                            relation: 'productCategory',
                            scope: {
                                include: {
                                    relation: 'productCategory',
                                },
                            },
                        },
                    ],
                })
                    .toPromise();
            }
        });
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------
    /**
     * @description Select the standardProduct
     * @param standardProduct
     * @returns
     */
    add(standardProduct) {
        this.activeModal.close(standardProduct);
    }
}
