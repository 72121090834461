/* tslint:disable */
import { InternalNote, Correspondence, StandardOrderProduct } from '../index';

declare var Object: any;
export interface StandardOrderInterface {
  retourDeliveryDate?: Date;
  paymentStatus: string;
  saleLocation?: string;
  selectedReasonDeleted?: string;
  mendrixOrderId?: number;
  mendrixRetourOrderId?: number;
  mailSent?: any;
  orderId?: any;
  withAssembly?: boolean;
  delivery?: any;
  shoppingCartId?: any;
  status: string;
  discountRecords?: Array<any>;
  extraProductDiscount?: number;
  notes?: string;
  disableCommunication: boolean;
  id?: any;
  invoiceDivisionId?: any;
  shippingDivisionId?: any;
  flagId?: any;
  userIdDeleted?: any;
  dateCreated?: Date;
  dateModified?: Date;
  userIdCreated?: string;
  userIdModified?: string;
  firstName: string;
  insertion?: string;
  lastName: string;
  emailAddress: string;
  shippingStreet: string;
  shippingHouseNumber: string;
  shippingZip: string;
  shippingCity: string;
  shippingPhone: string;
  shippingProvince?: string;
  shippingMunicipality?: string;
  shippingLocation?: any;
  invoiceStreet: string;
  invoiceHouseNumber: string;
  invoiceZip: string;
  invoiceCity: string;
  invoiceEmail: string;
  invoicePhone: string;
  invoiceCompany?: string;
  invoiceReference?: string;
  invoiceVAT?: string;
  invoiceProvince?: string;
  invoiceMunicipality?: string;
  invoiceLocation?: any;
  exactAccountId?: string;
  callAppointment?: Date;
  standardOrderNumber: string;
  deleted?: boolean;
  dateDeleted?: Date;
  exactInvoicedAmount?: number;
  exactSalesOrderId?: string;
  exactSalesInvoiceIds?: Array<any>;
  exactSalesInvoiceAttachmentIds?: Array<any>;
  exactInvoiceRemark?: string;
  bumbalDeliveryId?: string;
  bumbalDeliveryExtraTime?: number;
  bumbalDeliveryTotalTime?: number;
  deliveryDate?: Date;
  bumbalDeliveryEmployee?: string;
  bumbalDeliveryStatus?: string;
  appointmentCosts?: Array<any>;
  deliveryCosts?: number;
  bumbalFlow?: string;
  bumbalShipmentId?: string;
  bumbalShipmentExtraTime?: number;
  bumbalShipmentTotalTime?: number;
  shipmentDate?: Date;
  bumbalShipmentEmployee?: string;
  bumbalShipmentStatus?: string;
  pickupWarehouse?: string;
  dhlDraftId?: string;
  payments?: any[];
  invoiceDivision?: any;
  shippingDivision?: any;
  order?: any;
  flag?: any;
  userDeleted?: any;
  internalNote?: InternalNote[];
  correspondences?: Correspondence[];
  cancelQuestion?: any;
  deliveryForm?: any;
  shoppingCart?: any;
  services?: any[];
  warranty?: any;
  price?: any;
  products?: StandardOrderProduct[];
}

export class StandardOrder implements StandardOrderInterface {
  'retourDeliveryDate': Date;
  'paymentStatus': string;
  'saleLocation': string;
  'selectedReasonDeleted': string;
  'mendrixOrderId': number;
  'mendrixRetourOrderId': number;
  'mailSent': any;
  'orderId': any;
  'withAssembly': boolean;
  'delivery': any;
  'shoppingCartId': any;
  'status': string;
  'discountRecords': Array<any>;
  'extraProductDiscount': number;
  'notes': string;
  'disableCommunication': boolean;
  'id': any;
  'invoiceDivisionId': any;
  'shippingDivisionId': any;
  'flagId': any;
  'userIdDeleted': any;
  'dateCreated': Date;
  'dateModified': Date;
  'userIdCreated': string;
  'userIdModified': string;
  'firstName': string;
  'insertion': string;
  'lastName': string;
  'emailAddress': string;
  'shippingStreet': string;
  'shippingHouseNumber': string;
  'shippingZip': string;
  'shippingCity': string;
  'shippingPhone': string;
  'shippingProvince': string;
  'shippingMunicipality': string;
  'shippingLocation': any;
  'invoiceStreet': string;
  'invoiceHouseNumber': string;
  'invoiceZip': string;
  'invoiceCity': string;
  'invoiceEmail': string;
  'invoicePhone': string;
  'invoiceCompany': string;
  'invoiceReference': string;
  'invoiceVAT': string;
  'invoiceProvince': string;
  'invoiceMunicipality': string;
  'invoiceLocation': any;
  'exactAccountId': string;
  'callAppointment': Date;
  'standardOrderNumber': string;
  'deleted': boolean;
  'dateDeleted': Date;
  'exactInvoicedAmount': number;
  'exactSalesOrderId': string;
  'exactSalesInvoiceIds': Array<any>;
  'exactSalesInvoiceAttachmentIds': Array<any>;
  'exactInvoiceRemark': string;
  'bumbalDeliveryId': string;
  'bumbalDeliveryExtraTime': number;
  'bumbalDeliveryTotalTime': number;
  'deliveryDate': Date;
  'bumbalDeliveryEmployee': string;
  'bumbalDeliveryStatus': string;
  'appointmentCosts': Array<any>;
  'deliveryCosts': number;
  'bumbalFlow': string;
  'bumbalShipmentId': string;
  'bumbalShipmentExtraTime': number;
  'bumbalShipmentTotalTime': number;
  'shipmentDate': Date;
  'bumbalShipmentEmployee': string;
  'bumbalShipmentStatus': string;
  'pickupWarehouse': string;
  'dhlDraftId': string;
  payments: any[];
  invoiceDivision: any;
  shippingDivision: any;
  order: any;
  flag: any;
  userDeleted: any;
  internalNote: InternalNote[];
  correspondences: Correspondence[];
  cancelQuestion: any;
  deliveryForm: any;
  shoppingCart: any;
  services: any[];
  warranty: any;
  price: any;
  products: StandardOrderProduct[];
  constructor(data?: StandardOrderInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `StandardOrder`.
   */
  public static getModelName() {
    return 'StandardOrder';
  }
  /**
   * @method factory
   * @author Jonathan Casarrubias
   * @license MIT
   * This method creates an instance of StandardOrder for dynamic purposes.
   **/
  public static factory(data: StandardOrderInterface): StandardOrder {
    return new StandardOrder(data);
  }
  /**
   * @method getModelDefinition
   * @author Julien Ledun
   * @license MIT
   * This method returns an object that represents some of the model
   * definitions.
   **/
  public static getModelDefinition() {
    return {
      name: 'StandardOrder',
      plural: 'StandardOrders',
      path: 'StandardOrders',
      idName: 'id',
      properties: {
        retourDeliveryDate: {
          name: 'retourDeliveryDate',
          type: 'Date',
        },
        paymentStatus: {
          name: 'paymentStatus',
          type: 'string',
          default: 'notPaid',
        },
        saleLocation: {
          name: 'saleLocation',
          type: 'string',
        },
        selectedReasonDeleted: {
          name: 'selectedReasonDeleted',
          type: 'string',
        },
        mendrixOrderId: {
          name: 'mendrixOrderId',
          type: 'number',
        },
        mendrixRetourOrderId: {
          name: 'mendrixRetourOrderId',
          type: 'number',
        },
        mailSent: {
          name: 'mailSent',
          type: 'any',
        },
        orderId: {
          name: 'orderId',
          type: 'any',
        },
        withAssembly: {
          name: 'withAssembly',
          type: 'boolean',
          default: false,
        },
        delivery: {
          name: 'delivery',
          type: 'any',
        },
        shoppingCartId: {
          name: 'shoppingCartId',
          type: 'any',
        },
        status: {
          name: 'status',
          type: 'string',
          default: 'created',
        },
        discountRecords: {
          name: 'discountRecords',
          type: 'Array&lt;any&gt;',
        },
        extraProductDiscount: {
          name: 'extraProductDiscount',
          type: 'number',
          default: 0,
        },
        notes: {
          name: 'notes',
          type: 'string',
        },
        disableCommunication: {
          name: 'disableCommunication',
          type: 'boolean',
          default: false,
        },
        id: {
          name: 'id',
          type: 'any',
        },
        invoiceDivisionId: {
          name: 'invoiceDivisionId',
          type: 'any',
        },
        shippingDivisionId: {
          name: 'shippingDivisionId',
          type: 'any',
        },
        flagId: {
          name: 'flagId',
          type: 'any',
        },
        userIdDeleted: {
          name: 'userIdDeleted',
          type: 'any',
        },
        dateCreated: {
          name: 'dateCreated',
          type: 'Date',
        },
        dateModified: {
          name: 'dateModified',
          type: 'Date',
        },
        userIdCreated: {
          name: 'userIdCreated',
          type: 'string',
        },
        userIdModified: {
          name: 'userIdModified',
          type: 'string',
        },
        firstName: {
          name: 'firstName',
          type: 'string',
        },
        insertion: {
          name: 'insertion',
          type: 'string',
        },
        lastName: {
          name: 'lastName',
          type: 'string',
        },
        emailAddress: {
          name: 'emailAddress',
          type: 'string',
        },
        shippingStreet: {
          name: 'shippingStreet',
          type: 'string',
        },
        shippingHouseNumber: {
          name: 'shippingHouseNumber',
          type: 'string',
        },
        shippingZip: {
          name: 'shippingZip',
          type: 'string',
        },
        shippingCity: {
          name: 'shippingCity',
          type: 'string',
        },
        shippingPhone: {
          name: 'shippingPhone',
          type: 'string',
        },
        shippingProvince: {
          name: 'shippingProvince',
          type: 'string',
        },
        shippingMunicipality: {
          name: 'shippingMunicipality',
          type: 'string',
        },
        shippingLocation: {
          name: 'shippingLocation',
          type: 'any',
        },
        invoiceStreet: {
          name: 'invoiceStreet',
          type: 'string',
        },
        invoiceHouseNumber: {
          name: 'invoiceHouseNumber',
          type: 'string',
        },
        invoiceZip: {
          name: 'invoiceZip',
          type: 'string',
        },
        invoiceCity: {
          name: 'invoiceCity',
          type: 'string',
        },
        invoiceEmail: {
          name: 'invoiceEmail',
          type: 'string',
        },
        invoicePhone: {
          name: 'invoicePhone',
          type: 'string',
        },
        invoiceCompany: {
          name: 'invoiceCompany',
          type: 'string',
        },
        invoiceReference: {
          name: 'invoiceReference',
          type: 'string',
        },
        invoiceVAT: {
          name: 'invoiceVAT',
          type: 'string',
        },
        invoiceProvince: {
          name: 'invoiceProvince',
          type: 'string',
        },
        invoiceMunicipality: {
          name: 'invoiceMunicipality',
          type: 'string',
        },
        invoiceLocation: {
          name: 'invoiceLocation',
          type: 'any',
        },
        exactAccountId: {
          name: 'exactAccountId',
          type: 'string',
        },
        callAppointment: {
          name: 'callAppointment',
          type: 'Date',
        },
        standardOrderNumber: {
          name: 'standardOrderNumber',
          type: 'string',
        },
        deleted: {
          name: 'deleted',
          type: 'boolean',
        },
        dateDeleted: {
          name: 'dateDeleted',
          type: 'Date',
        },
        exactInvoicedAmount: {
          name: 'exactInvoicedAmount',
          type: 'number',
        },
        exactSalesOrderId: {
          name: 'exactSalesOrderId',
          type: 'string',
        },
        exactSalesInvoiceIds: {
          name: 'exactSalesInvoiceIds',
          type: 'Array&lt;any&gt;',
        },
        exactSalesInvoiceAttachmentIds: {
          name: 'exactSalesInvoiceAttachmentIds',
          type: 'Array&lt;any&gt;',
        },
        exactInvoiceRemark: {
          name: 'exactInvoiceRemark',
          type: 'string',
        },
        bumbalDeliveryId: {
          name: 'bumbalDeliveryId',
          type: 'string',
        },
        bumbalDeliveryExtraTime: {
          name: 'bumbalDeliveryExtraTime',
          type: 'number',
          default: 0,
        },
        bumbalDeliveryTotalTime: {
          name: 'bumbalDeliveryTotalTime',
          type: 'number',
        },
        deliveryDate: {
          name: 'deliveryDate',
          type: 'Date',
        },
        bumbalDeliveryEmployee: {
          name: 'bumbalDeliveryEmployee',
          type: 'string',
        },
        bumbalDeliveryStatus: {
          name: 'bumbalDeliveryStatus',
          type: 'string',
        },
        appointmentCosts: {
          name: 'appointmentCosts',
          type: 'Array&lt;any&gt;',
        },
        deliveryCosts: {
          name: 'deliveryCosts',
          type: 'number',
          default: 0,
        },
        bumbalFlow: {
          name: 'bumbalFlow',
          type: 'string',
          default: 'default',
        },
        bumbalShipmentId: {
          name: 'bumbalShipmentId',
          type: 'string',
        },
        bumbalShipmentExtraTime: {
          name: 'bumbalShipmentExtraTime',
          type: 'number',
          default: 0,
        },
        bumbalShipmentTotalTime: {
          name: 'bumbalShipmentTotalTime',
          type: 'number',
        },
        shipmentDate: {
          name: 'shipmentDate',
          type: 'Date',
        },
        bumbalShipmentEmployee: {
          name: 'bumbalShipmentEmployee',
          type: 'string',
        },
        bumbalShipmentStatus: {
          name: 'bumbalShipmentStatus',
          type: 'string',
        },
        pickupWarehouse: {
          name: 'pickupWarehouse',
          type: 'string',
        },
        dhlDraftId: {
          name: 'dhlDraftId',
          type: 'string',
        },
      },
      relations: {
        payments: {
          name: 'payments',
          type: 'any[]',
          model: '',
          relationType: 'hasMany',
          keyFrom: 'id',
          keyTo: 'orderId',
        },
        invoiceDivision: {
          name: 'invoiceDivision',
          type: 'any',
          model: '',
          relationType: 'belongsTo',
          keyFrom: 'invoiceDivisionId',
          keyTo: 'id',
        },
        shippingDivision: {
          name: 'shippingDivision',
          type: 'any',
          model: '',
          relationType: 'belongsTo',
          keyFrom: 'shippingDivisionId',
          keyTo: 'id',
        },
        order: {
          name: 'order',
          type: 'any',
          model: '',
          relationType: 'belongsTo',
          keyFrom: 'orderId',
          keyTo: 'id',
        },
        flag: {
          name: 'flag',
          type: 'any',
          model: '',
          relationType: 'belongsTo',
          keyFrom: 'flagId',
          keyTo: 'id',
        },
        userDeleted: {
          name: 'userDeleted',
          type: 'any',
          model: '',
          relationType: 'belongsTo',
          keyFrom: 'userIdDeleted',
          keyTo: 'id',
        },
        internalNote: {
          name: 'internalNote',
          type: 'InternalNote[]',
          model: 'InternalNote',
          relationType: 'hasMany',
          keyFrom: 'id',
          keyTo: 'linkId',
        },
        correspondences: {
          name: 'correspondences',
          type: 'Correspondence[]',
          model: 'Correspondence',
          relationType: 'hasMany',
          keyFrom: 'id',
          keyTo: 'fkId',
        },
        cancelQuestion: {
          name: 'cancelQuestion',
          type: 'any',
          model: '',
          relationType: 'belongsTo',
          keyFrom: 'selectedReasonDeleted',
          keyTo: 'code',
        },
        deliveryForm: {
          name: 'deliveryForm',
          type: 'any',
          model: '',
          relationType: 'hasOne',
          keyFrom: 'id',
          keyTo: 'modelId',
        },
        shoppingCart: {
          name: 'shoppingCart',
          type: 'any',
          model: '',
          relationType: 'belongsTo',
          keyFrom: 'shoppingCartId',
          keyTo: 'id',
        },
        services: {
          name: 'services',
          type: 'any[]',
          model: '',
          relationType: 'hasMany',
          keyFrom: 'id',
          keyTo: 'orderId',
        },
        warranty: {
          name: 'warranty',
          type: 'any',
          model: '',
          relationType: 'hasOne',
          keyFrom: 'id',
          keyTo: 'orderId',
        },
        price: {
          name: 'price',
          type: 'any',
          model: '',
          relationType: 'hasOne',
          keyFrom: 'id',
          keyTo: 'standardOrderId',
        },
        products: {
          name: 'products',
          type: 'StandardOrderProduct[]',
          model: 'StandardOrderProduct',
          relationType: 'hasMany',
          keyFrom: 'id',
          keyTo: 'standardOrderId',
        },
      },
    };
  }
}
