/* tslint:disable */
import {
  Order,
  Product,
  ProductieStaatConfigurationVersion,
  Service,
  Rack,
  RackSlot,
} from '../index';

declare var Object: any;
export interface ProductieStaatInterface {
  errors?: Array<any>;
  statusFlow: any;
  orderId: any;
  versionId: any;
  productId?: any;
  serviceId?: any;
  elementIndex?: number;
  counter: number;
  label?: string;
  configuration: any;
  glass_cutter?: any;
  orderpicking?: any;
  saw?: any;
  drill?: any;
  assemble?: any;
  compile?: any;
  final_check?: any;
  reject?: Array<any>;
  bumbalId?: string;
  date_bumbal?: Date;
  date_orderpicking?: Date;
  date_saw?: Date;
  date_drill?: Date;
  date_glass_cutter?: Date;
  date_compile?: Date;
  date_assemble?: Date;
  date_final_check?: Date;
  date_load?: Date;
  date_load_last_sync?: Date;
  load?: any;
  exactShopOrderIds?: Array<any>;
  exactShopOrderMaterialsPlans?: any;
  exactStageForDeliveryReceiptsShopOrderIds?: Array<any>;
  exactReceiptsShopOrderIds?: Array<any>;
  exactGoodsDeliveriesShopOrderIds?: Array<any>;
  exactFinishProduction?: boolean;
  questions?: Array<any>;
  isFinished: boolean;
  id?: any;
  dateCreated?: Date;
  dateModified?: Date;
  orderNumber: string;
  order?: Order;
  product?: Product;
  version?: ProductieStaatConfigurationVersion;
  service?: Service;
  racks?: Rack[];
  rackSlots?: RackSlot[];
}

export class ProductieStaat implements ProductieStaatInterface {
  'errors': Array<any>;
  'statusFlow': any;
  'orderId': any;
  'versionId': any;
  'productId': any;
  'serviceId': any;
  'elementIndex': number;
  'counter': number;
  'label': string;
  'configuration': any;
  'glass_cutter': any;
  'orderpicking': any;
  'saw': any;
  'drill': any;
  'assemble': any;
  'compile': any;
  'final_check': any;
  'reject': Array<any>;
  'bumbalId': string;
  'date_bumbal': Date;
  'date_orderpicking': Date;
  'date_saw': Date;
  'date_drill': Date;
  'date_glass_cutter': Date;
  'date_compile': Date;
  'date_assemble': Date;
  'date_final_check': Date;
  'date_load': Date;
  'date_load_last_sync': Date;
  'load': any;
  'exactShopOrderIds': Array<any>;
  'exactShopOrderMaterialsPlans': any;
  'exactStageForDeliveryReceiptsShopOrderIds': Array<any>;
  'exactReceiptsShopOrderIds': Array<any>;
  'exactGoodsDeliveriesShopOrderIds': Array<any>;
  'exactFinishProduction': boolean;
  'questions': Array<any>;
  'isFinished': boolean;
  'id': any;
  'dateCreated': Date;
  'dateModified': Date;
  'orderNumber': string;
  order: Order;
  product: Product;
  version: ProductieStaatConfigurationVersion;
  service: Service;
  racks: Rack[];
  rackSlots: RackSlot[];
  constructor(data?: ProductieStaatInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `ProductieStaat`.
   */
  public static getModelName() {
    return 'ProductieStaat';
  }
  /**
   * @method factory
   * @author Jonathan Casarrubias
   * @license MIT
   * This method creates an instance of ProductieStaat for dynamic purposes.
   **/
  public static factory(data: ProductieStaatInterface): ProductieStaat {
    return new ProductieStaat(data);
  }
  /**
   * @method getModelDefinition
   * @author Julien Ledun
   * @license MIT
   * This method returns an object that represents some of the model
   * definitions.
   **/
  public static getModelDefinition() {
    return {
      name: 'ProductieStaat',
      plural: 'ProductieStaten',
      path: 'ProductieStaten',
      idName: 'id',
      properties: {
        errors: {
          name: 'errors',
          type: 'Array&lt;any&gt;',
        },
        statusFlow: {
          name: 'statusFlow',
          type: 'any',
        },
        orderId: {
          name: 'orderId',
          type: 'any',
        },
        versionId: {
          name: 'versionId',
          type: 'any',
        },
        productId: {
          name: 'productId',
          type: 'any',
        },
        serviceId: {
          name: 'serviceId',
          type: 'any',
        },
        elementIndex: {
          name: 'elementIndex',
          type: 'number',
        },
        counter: {
          name: 'counter',
          type: 'number',
          default: 1,
        },
        label: {
          name: 'label',
          type: 'string',
        },
        configuration: {
          name: 'configuration',
          type: 'any',
        },
        glass_cutter: {
          name: 'glass_cutter',
          type: 'any',
        },
        orderpicking: {
          name: 'orderpicking',
          type: 'any',
        },
        saw: {
          name: 'saw',
          type: 'any',
        },
        drill: {
          name: 'drill',
          type: 'any',
        },
        assemble: {
          name: 'assemble',
          type: 'any',
        },
        compile: {
          name: 'compile',
          type: 'any',
        },
        final_check: {
          name: 'final_check',
          type: 'any',
        },
        reject: {
          name: 'reject',
          type: 'Array&lt;any&gt;',
        },
        bumbalId: {
          name: 'bumbalId',
          type: 'string',
        },
        date_bumbal: {
          name: 'date_bumbal',
          type: 'Date',
        },
        date_orderpicking: {
          name: 'date_orderpicking',
          type: 'Date',
        },
        date_saw: {
          name: 'date_saw',
          type: 'Date',
        },
        date_drill: {
          name: 'date_drill',
          type: 'Date',
        },
        date_glass_cutter: {
          name: 'date_glass_cutter',
          type: 'Date',
        },
        date_compile: {
          name: 'date_compile',
          type: 'Date',
        },
        date_assemble: {
          name: 'date_assemble',
          type: 'Date',
        },
        date_final_check: {
          name: 'date_final_check',
          type: 'Date',
        },
        date_load: {
          name: 'date_load',
          type: 'Date',
        },
        date_load_last_sync: {
          name: 'date_load_last_sync',
          type: 'Date',
        },
        load: {
          name: 'load',
          type: 'any',
        },
        exactShopOrderIds: {
          name: 'exactShopOrderIds',
          type: 'Array&lt;any&gt;',
        },
        exactShopOrderMaterialsPlans: {
          name: 'exactShopOrderMaterialsPlans',
          type: 'any',
        },
        exactStageForDeliveryReceiptsShopOrderIds: {
          name: 'exactStageForDeliveryReceiptsShopOrderIds',
          type: 'Array&lt;any&gt;',
        },
        exactReceiptsShopOrderIds: {
          name: 'exactReceiptsShopOrderIds',
          type: 'Array&lt;any&gt;',
        },
        exactGoodsDeliveriesShopOrderIds: {
          name: 'exactGoodsDeliveriesShopOrderIds',
          type: 'Array&lt;any&gt;',
        },
        exactFinishProduction: {
          name: 'exactFinishProduction',
          type: 'boolean',
        },
        questions: {
          name: 'questions',
          type: 'Array&lt;any&gt;',
        },
        isFinished: {
          name: 'isFinished',
          type: 'boolean',
          default: false,
        },
        id: {
          name: 'id',
          type: 'any',
        },
        dateCreated: {
          name: 'dateCreated',
          type: 'Date',
        },
        dateModified: {
          name: 'dateModified',
          type: 'Date',
        },
        orderNumber: {
          name: 'orderNumber',
          type: 'string',
        },
      },
      relations: {
        order: {
          name: 'order',
          type: 'Order',
          model: 'Order',
          relationType: 'belongsTo',
          keyFrom: 'orderId',
          keyTo: 'id',
        },
        product: {
          name: 'product',
          type: 'Product',
          model: 'Product',
          relationType: 'belongsTo',
          keyFrom: 'productId',
          keyTo: 'id',
        },
        version: {
          name: 'version',
          type: 'ProductieStaatConfigurationVersion',
          model: 'ProductieStaatConfigurationVersion',
          relationType: 'belongsTo',
          keyFrom: 'versionId',
          keyTo: 'id',
        },
        service: {
          name: 'service',
          type: 'Service',
          model: 'Service',
          relationType: 'belongsTo',
          keyFrom: 'serviceId',
          keyTo: 'id',
        },
        racks: {
          name: 'racks',
          type: 'Rack[]',
          model: 'Rack',
          relationType: 'hasMany',
          modelThrough: 'RackSlot',
          keyThrough: 'rackId',
          keyFrom: 'id',
          keyTo: 'productieStaatId',
        },
        rackSlots: {
          name: 'rackSlots',
          type: 'RackSlot[]',
          model: 'RackSlot',
          relationType: 'hasMany',
          keyFrom: 'id',
          keyTo: 'productieStaatId',
        },
      },
    };
  }
}
