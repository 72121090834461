/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./standard-product-list-modal.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../standard-product-add/standard-product-add.component.ngfactory";
import * as i3 from "../standard-product-add/standard-product-add.component";
import * as i4 from "./standard-product-list-modal.component";
import * as i5 from "@ng-bootstrap/ng-bootstrap";
import * as i6 from "../../../_sdk/services/custom/StandardProduct";
var styles_StandardProductListModalComponent = [i0.styles];
var RenderType_StandardProductListModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_StandardProductListModalComponent, data: {} });
export { RenderType_StandardProductListModalComponent as RenderType_StandardProductListModalComponent };
function View_StandardProductListModalComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h4", [["class", "modal-title"], ["id", "modal-basic-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Bevestiging"])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "modal-body mb-4"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Weet u zeker dat u deze accessoire wilt toevoegen? "])), (_l()(), i1.ɵeld(5, 0, null, null, 4, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "button", [["class", "btn btn-outline-dark"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (_v.context.$implicit.dismiss() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" Annuleren "])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "button", [["class", "btn btn-outline-dark"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (_v.context.$implicit.close("confirm") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" Toevoegen "]))], null, null); }
export function View_StandardProductListModalComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "filter-title-bar"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "container-fluid"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "d-flex flex-row align-items-center justify-content-between mb-3 mt-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "h5", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Kies een product"])), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "supply-product-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "app-standard-product-add", [], null, [[null, "add"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("add" === en)) {
        var pd_0 = (_co.add($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_StandardProductAddComponent_0, i2.RenderType_StandardProductAddComponent)), i1.ɵdid(7, 49152, null, 0, i3.StandardProductAddComponent, [], { standardProducts: [0, "standardProducts"] }, { add: "add" }), (_l()(), i1.ɵand(0, [["confirmInvalidDialog", 2]], null, 0, null, View_StandardProductListModalComponent_1))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.standardProducts; _ck(_v, 7, 0, currVal_0); }, null); }
export function View_StandardProductListModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-standard-product-list-modal", [], null, null, null, View_StandardProductListModalComponent_0, RenderType_StandardProductListModalComponent)), i1.ɵdid(1, 114688, null, 0, i4.StandardProductListModalComponent, [i5.NgbActiveModal, i6.StandardProductApi], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var StandardProductListModalComponentNgFactory = i1.ɵccf("app-standard-product-list-modal", i4.StandardProductListModalComponent, View_StandardProductListModalComponent_Host_0, { standardProducts: "standardProducts" }, {}, []);
export { StandardProductListModalComponentNgFactory as StandardProductListModalComponentNgFactory };
