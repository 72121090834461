import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxPermissionsModule } from 'ngx-permissions';
import { NgxGalleryModule } from '@kolkov/ngx-gallery';
import { FileUploadModule } from 'ng2-file-upload';
import { NgbDateParserFormatter, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';

import {
  BumbalDateSelectorComponent,
  NgbdSortableHeader,
  UploadComponent,
  SortPipe,
  MyUploadComponent,
  UploadAudioComponent,
  SupplyProductAddComponent,
  JoinPipe,
  StandardProductAddComponent,
} from './';
import { AudioRecordingService } from './_helpers';
import { SafePipe } from './_pipes/safe.pipe';
import { ImagePipe } from './_pipes/image.pipe';
import { CustomerServiceMessageModalComponent } from './_components/customer-service-message-modal/customer-service-message-modal.component';
import { SupplyProductListModalComponent } from './_components/supply-product-list-modal/supply-product-list-modal.component';
import { StandardProductListModalComponent } from './';
import { MomentDateFormatter } from './_utils/dateFormat';
import { AfterIfDirective } from './_directives/after-if.directive';
import { BlobUploadComponent } from './_components/upload-blob/upload-blob.component';
import { PaymentHistoryComponent } from './_components/payment-history/payment-history.component';

@NgModule({
  imports: [
    CommonModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    NgxPermissionsModule,
    FileUploadModule,
    NgxGalleryModule,
    NgSelectModule,
  ],
  declarations: [
    NgbdSortableHeader,
    UploadComponent,
    MyUploadComponent,
    BumbalDateSelectorComponent,
    SortPipe,
    UploadAudioComponent,
    SafePipe,
    ImagePipe,
    JoinPipe,
    CustomerServiceMessageModalComponent,
    SupplyProductAddComponent,
    SupplyProductListModalComponent,
    StandardProductListModalComponent,
    StandardProductAddComponent,
    AfterIfDirective,
    BlobUploadComponent,
    PaymentHistoryComponent,
  ],
  exports: [
    CommonModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    NgbdSortableHeader,
    NgxPermissionsModule,
    UploadComponent,
    MyUploadComponent,
    FileUploadModule,
    NgxGalleryModule,
    NgSelectModule,
    BumbalDateSelectorComponent,
    SortPipe,
    UploadAudioComponent,
    ImagePipe,
    JoinPipe,
    CustomerServiceMessageModalComponent,
    SupplyProductAddComponent,
    SupplyProductListModalComponent,
    StandardProductListModalComponent,
    StandardProductAddComponent,
    AfterIfDirective,
    BlobUploadComponent,
    PaymentHistoryComponent,
  ],
  providers: [
    AudioRecordingService,
    { provide: NgbDateParserFormatter, useClass: MomentDateFormatter },
  ],
  entryComponents: [
    CustomerServiceMessageModalComponent,
    SupplyProductListModalComponent,
    StandardProductListModalComponent,
  ],
})
export class SharedModule {}
